@media (min-width: 1650px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1290px !important;
    }

    .vimeo-modal {
        padding: none;
        max-width: 780px;
        height: 420px;
        width: 100%;
        position: relative;
    }

    .modal-content iframe {
        width: 800px;
        height: 450px;
    }
}

@media (max-width: 1599px) {
    .main-containe {
        padding: 0 20px 0 35px;
    }
}

@media (max-width: 1399px) {
    .main-containe {
        max-width: 880px;
        padding: 0 20px 0 35px;
    }

    #progress {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
        gap: 30px;
    }

    .design-skill-sub-section {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin: 46px 0 80px 0;
        justify-content: space-between;
        gap: 30px;
    }

    .jessica-main-text {
        font-size: 80px;
        line-height: 90px;
    }

    .section-one {
        padding: 60px 0 120px 0;
    }

    .About-section,
    .coding-skill-section,
    .Services-section,
    .Pricing-section,
    .blog-section {

        padding: 50px 0;
    }

    .about-sub-text {
        padding: 20px 0 20px 0;
    }

    .design-skill-sub-section {
        margin: 30px 0 50px 0;
    }

    .awards-row {
        padding-top: 30px;
    }

    .choose-plain-btn-main {
        padding: 30px 20px;
    }

    .development-box {
        padding: 20px;
    }

    .blog-main {
        gap: 30px;
        margin: 30px 0;
    }
}

@media (max-width: 1199px) {
    .menu-toggle-button {
        display: block;
    }

    .menu-toggle-button {
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 10;
        background: rgba(255, 255, 255, 0.25);
        backdrop-filter: blur(6.5px);
        -webkit-backdrop-filter: blur(6.5px);
        border: none;
        cursor: pointer;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .side-menu-container {
        position: relative;
    }

    .side-menu {
        position: fixed;
        top: 0;
        left: -250px;
        width: 250px;
        height: 100%;
        transition: left 0.3s ease;
        z-index: 499;
    }

    .side-menu.show {
        left: 0;
    }

    .hamburger .line {
        width: 25px;
        height: 2px;
        background-color: #FFF;
        display: block;
        margin: 6px auto;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    .hamburger:hover {
        cursor: pointer;
    }

    #hamburger-1.is-active .line:nth-child(2) {
        opacity: 0;
    }

    #hamburger-1.is-active .line:nth-child(1) {
        -webkit-transform: translateY(8px) rotate(48deg);
        -ms-transform: translateY(8px) rotate(48deg);
        -o-transform: translateY(8px) rotate(48deg);
        transform: translateY(8px) rotate(48deg);
    }

    #hamburger-1.is-active .line:nth-child(3) {
        -webkit-transform: translateY(-8px) rotate(-48deg);
        -ms-transform: translateY(-8px) rotate(-48deg);
        -o-transform: translateY(-8px) rotate(-48deg);
        transform: translateY(-8px) rotate(-48deg);
    }

    .main-containe {
        max-width: 100%;
        padding: 0px 20px;
    }

    .services-row {
        gap: 30px 0;
    }

    .form-container {
        margin-top: 40px;
    }

    .map-iframe {
        margin-bottom: 0;
    }

    .popup-content2 {
        padding: 30px;
    }
}

@media (max-width: 991px) {
    .section-one {
        padding: 50px 0 110px 0;
    }

    .section-one .row {
        text-align: center;
        gap: 30px 0;
    }

    .best {
        max-width: 100%;
    }

    .section-one-btns-main {
        gap: 15px;
        margin-top: 40px;
        justify-content: center;
    }

    .section-one-btns-main {
        margin-top: 25px;
    }

    .back-End-dev {
        font-size: 22px;
        line-height: 30px;
        padding: 15px 0;
    }

    .circular-img {
        left: 40px;
    }

    .flower {
        left: 155px;
    }

    .Marquee-main h3 {
        text-align: center;
        font-size: 22px;
    }

    .About-section,
    .coding-skill-section,
    .Services-section,
    .Pricing-section,
    .blog-section {
        padding: 40px 0;
    }

    .design-skill-sub-section {
        margin: 25px 0 40px 0;
    }

    .education-experience .row {
        gap: 30px 0;
    }

    .education-heading-text {
        margin-bottom: 15px;
    }

    .awards-row {
        padding-top: 15px;
    }

    .services-product-text {
        padding: 15px 0 15px;
    }

    .portfolios-group-main {
        margin-top: 30px;
        flex-wrap: wrap;
    }

    .gallery-bank-section {
        display: flex;
        gap: 20px;
    }

    .pricing-section-main {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .basic-plain-box-main {
        width: 100%;
    }

    .basic-plain-box,
    .basic-plain-box-sub {
        max-width: 100%;
    }

    .blog-img-section {
        height: 250px;
        width: 100%;
        max-width: 100%;
        background-position: top center;
    }

    .blog-main {
        display: flex;
        align-items: center;
        gap: 30px;
        margin: 30px 0;
        flex-direction: column;
    }

    .section-heading-text,
    .education-heading-text {
        font-size: 42px;
        line-height: 50px;
    }

    .popup-content2 {
        width: 767px;
        padding: 20px;
    }

    .popup-box {
        width: 767px;
        height: 90vh;
        padding: 30px;
    }

    .date-onner-coment-main {
        display: flex;
        align-items: center;
        gap: 15px;
        flex-wrap: wrap;
    }

    .simple-steps {
        font-size: 25px;
    }

    .blog-popup-img1 {
        max-width: 100%;
        border-radius: 20px;
        margin: 20px 0;
    }

    .popup-content {
        width: 90%;
        height: 90vh;
    }
}

@media(max-width:767px) {
    .fix-bg {
        background: #0F141C;
    }

    ::-webkit-scrollbar {
        width: 0;
    }

    .jessica-main-text {
        font-size: 60px;
        line-height: 70px;
    }

    .back-End-dev {
        font-size: 20px;
        line-height: 26px;
        padding: 10px 0;
    }

    .best {
        font-size: 18px;
    }

    .jessica-main-img {
        padding-top: 35px;
    }

    .circular-img {
        left: -46px;
        top: -22px;
    }

    .flower {
        top: 90px;
        left: 66px;
    }

    .worked-more {
        font-size: 18px;
    }

    .marquee-img-main {
        padding: 0 40px;
        height: 65px;
        width: 200px;
    }

    .section-heading-text,
    .education-heading-text {
        font-size: 38px;
        line-height: 40px;
    }

    .About-section,
    .coding-skill-section,
    .Services-section,
    .Pricing-section,
    .blog-section {
        padding: 30px 0;
    }

    .section-sub-text {
        font-size: 18px;
    }

    .about-sub-text {
        padding: 15px 0 15px 0;
    }

    .about-detail {
        font-size: 18px;
        line-height: 26px;
        max-width: 130px;
    }

    .about-detail-info {
        font-size: 18px;
        line-height: 26px;
    }

    .bachelor {
        font-size: 18px;
        line-height: 24px;
        padding-bottom: 5px;
    }

    .university {
        padding-bottom: 9px;
    }

    .progress-item {
        width: 120px;
        height: 120px;
    }

    #progress {
        gap: 20px;
    }

    .progress-item::after {
        font-size: 25px;
    }

    .design-skill-sub-section {
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-between;
        gap: 20px;
        margin: 25px 0 30px 0;
    }

    .services-product-text {
        font-size: 18px;
        padding: 10px 0 10px;
    }

    .portfolios-group-main {
        margin-top: 25px;
        flex-wrap: wrap;
        justify-content: center;
    }

    .second-row-portfolio {
        margin-top: 20px;
    }

    .gallery-bank-section {
        display: flex;
        gap: 0;
        flex-direction: column;
    }

    .basic-plain-box-sub {
        padding: 30px;
    }

    .development-box {
        padding: 20px;
        flex-direction: column;
    }

    .choose-plain-btn-main {
        padding: 15px 0 0 0;
        border-left: none;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .taxes {
        margin-top: 20px;
        max-width: 100%;
    }

    .testament {
        margin: 20px 0;
    }

    .slick-dots {
        margin-top: 15px;
    }

    .steps {
        padding: 10px 0;
    }

    .blog-btn {
        margin-top: 20px;
    }

    .main-box-contact {
        grid-template-columns: repeat(1, 1fr);
    }

    .footer {
        padding: 20px 0;
    }

    .popup-box {
        width: 575px;
        height: 90vh;
        padding: 30px;
    }

    .main .container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}

@media(max-width:575px) {
    .main-containe {
        padding: 0px 10px;
        overflow-x: hidden;
    }

    .form-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-items: space-between;
    }

    #progress {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
        gap: 20px;
    }

    .map-iframe {
        width: 100%;
        height: 220px;
    }

    .view-all-btn {
        margin-top: 20px;
    }

    .basic-plain-text {
        padding-top: 10px;
    }

    .basic-plain-box-sub {
        padding: 20px;
    }

    .mon {
        font-size: 37px;
        line-height: 40px;
    }

    .avix {
        line-height: 26px;
    }

    .client-name {
        font-size: 21px;
    }

    .popup-box {
        width: 100%;
        height: 90vh;
        padding: 20px;
    }

    .Ornare {
        font-size: 16px;
        line-height: 27px;
        padding-top: 10px;
    }

    .pop-up-img-section2 img {
        max-width: 100%;
        border-radius: 20px;
        margin: 15px 0;
    }

    .corpo {
        font-size: 20px;
        line-height: 31px;
        padding-top: 15px;
    }

    .corpo-sub {
        font-size: 17px;
        line-height: 20px;
        padding: 10px 0 15px;
    }

    .simple-steps {
        font-size: 20px;
        margin-top: 10px;
    }

    .blog-popup-img1 {
        margin: 10px 0;
    }

    .blog-main {
        gap: 20px;
    }

    .blog-sit-text {
        font-size: 17px;
        line-height: 26px;
    }

    .services-popup-text {
        padding-bottom: 0;
    }
}

@media(max-width:500px) {
    .jessica-main-text {
        font-size: 47px;
        line-height: 50px;
    }

    .best {
        line-height: 26px;
    }

    .section-one-btns-main {
        margin-top: 15px;
    }

    .flower {
        display: none;
    }

    .worked-box {
        max-width: 330px;
        position: unset;
        margin: 20px auto;
        transform: translate(0);
    }

    .section-one {
        padding: 50px 0 0px 0;
    }

    .About-section,
    .coding-skill-section,
    .Services-section,
    .Pricing-section,
    .blog-section {
        padding: 20px 0;
    }

    .form-container {
        margin-top: 20px;
    }

    .contact-email a {
        font-size: 20px;
    }

    .mobile-icon-main {
        width: 70px;
        height: 70px;
    }

    .section-heading-text,
    .education-heading-text {
        font-size: 33px;
        line-height: 40px;
    }

    .about-detail {
        max-width: 100px;
    }

    .pricing-plaines-name {
        font-size: 17px;
        line-height: 30px;
        gap: 15px;
        margin-bottom: 13px;
    }

    .award-interior {
        font-size: 20px;
    }

    .pricing-section-main {
        margin: 20px 0 20px 0;
    }
}

@media(max-width:392px) {
    .section-one-btns-main {
        flex-direction: column;
    }

    .design-skill-sub-section {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
        justify-items: center
    }
}